<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <b-card>
      <template #header>
        <h4 class="card-title">
          Manage Library Modules
        </h4>
        <b-form-group
          class="mb-0"
          label="View as"
          label-for="btn-radios-1"
          label-size="sm"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="view"
            :options="[
              { text: 'Grid', value: 'grid' },
              { text: 'Table', value: 'table' },
            ]"
            buttons
            size="sm"
            button-variant="light"
          />
        </b-form-group>
      </template>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search Module"
            type="text"
          />
          <!-- <b-button
            v-b-modal.knowledgeBaseModal
            variant="primary"
            class="mr-1"
          >
            <feather-icon icon="PlusIcon" />
            Add Resource
          </b-button> -->
          <b-button
            v-b-modal.moduleModal
            variant="primary"
            @click="update = false"
          >
            <feather-icon icon="PlusIcon" />
            Add Module
          </b-button>
        </b-form-group>
      </div>
      <!-- card -->
      <section
        v-if="view === 'grid'"
        class="grid-view mt-1"
      >
        <b-row v-if="modules.length===0">
          <b-col>
            <b-card
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                No modules are available
              </h6>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            v-for="(row, i) in filteredModules"
            :key="i"
            :title="row.title"
            md="4"
          >
            <b-card
              :key="i"
              class="border border-secondary"
            >
              <template #header>
                <b-card-title class="d-flex align-items-center justify-content-center  mb-0 text-center">
                  {{ row.title }}
                </b-card-title>
                <div>
                  <b-badge
                    v-if="!row.is_default"
                    :variant="`${row.is_private ? 'primary' : 'secondary'}`"
                  >
                    <feather-icon :icon="`${row.is_private ? 'Lock' : 'Users'}Icon`" />
                    <span style="margin-left:4px">{{ row.is_private ? 'Private' : 'Public' }}</span>
                  </b-badge>
                  <b-badge
                    v-else
                    variant="outline-primary"
                  >
                    Default
                  </b-badge>
                </div>
              </template>
              <b-card-text>
                <vue-show-more-text
                  :text="row.description"
                  :lines="2"
                  additional-anchor-css="font-size: 12px; color:#7367F0"
                />
              </b-card-text>
              <b-card-footer class="p-0">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mr-50"
                      :to="{ name: 'library-details', params: { lid: row.id, mname: row.title } }"
                    >Open</b-button>
                    <b-button
                      v-if="!row.is_default"
                      v-b-modal.moduleModal
                      size="sm"
                      variant="outline-primary"
                      class="mr-50"
                      @click="update = true; moduletitle = row.title; moduledescription = row.description; is_private = row.is_private; selectedRow = row.id;"
                    >
                      Edit
                    </b-button>
                  </div>
                  <div>
                    <b-button-group>
                      <b-button
                        v-if="!row.is_default"
                        size="sm"
                        variant="flat-danger"
                        @click="selectedRow = row.id; deleteModule(row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- table -->
      <vue-good-table
        v-else
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="filteredModules"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'visibility'"
            class="d-flex flex-nowrap"
          >
            <b-badge
              v-if="!props.row.is_default"
              :variant="`${props.row.is_private ? 'primary' : 'secondary'}`"
            >
              <feather-icon :icon="`${props.row.is_private ? 'Lock' : 'Users'}Icon`" />
              <span style="margin-left:4px">{{ props.row.is_private ? 'Private' : 'Public' }}</span>
            </b-badge>
            <b-badge
              v-else
              variant="outline-primary"
            >
              Default
            </b-badge>
          </span>
          <span
            v-if="props.column.field === 'action'"
            class="d-flex flex-nowrap"
          >
            <b-button
              size="sm"
              variant="primary"
              class="mr-50"
              :to="{ name: 'library-details', params: { lid: props.row.id, mname: props.row.title } }"
            >Open</b-button>
            <b-button
              v-if="!props.row.is_default"
              v-b-modal.moduleModal
              size="sm"
              variant="outline-primary"
              class="mr-50"
              @click="update = true; moduletitle = props.row.title; moduledescription = props.row.description; is_private = props.row.is_private; selectedRow = props.row.id;"
            >Edit</b-button>
            <b-button
              v-if="!props.row.is_default"
              size="sm"
              variant="flat-danger"
              @click="selectedRow = props.row.id; deleteModule(props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="moduleModal"
        ok-only
        no-close-on-backdrop
        :ok-title="`${update ? 'Update' : 'Add'}`"
        size="lg"
        :title="`${update ? 'Update' : 'Add'} Module`"
        :ok-disabled="!moduletitle && !moduledescription"
        @ok="addModule"
      >
        <b-overlay :show="$apollo.loading || mutationLoading">
          <validation-observer
            ref="moduleForm"
            tag="form"
          >
            <b-row>
              <b-col cols="12">

                <b-form-group
                  label="Title"
                  label-size="sm"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="moduletitle"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Title"
                      required
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Description"
                  label-size="sm"
                >
                  <b-form-textarea
                    v-model="moduledescription"
                    placeholder="Module Description"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- primary -->
                <div class="d-flex align-items-center">
                  <span>Visibility</span>
                  <b-form-checkbox
                    v-model="is_private"
                    class="ml-50"
                    inline
                    switch
                  ><span class="switch-icon-left">
                     <feather-icon icon="UserIcon" />
                   </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="GlobeIcon" />
                    </span></b-form-checkbox>
                  <span>{{ is_private ? 'Private: Available only for incubatees, pre-incubatees and graduates.' : 'Public: Available for everyone in your network ' }}</span>
                </div>

              </b-col>
            </b-row>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-card>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/utils/auth'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, size } from '@core/utils/validations/validations'
import vueShowMoreText from 'vue-show-more-text'

export default {
  components: {
    BRow,
    vueShowMoreText,
    BCard,
    BCol,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BButton,
    BButtonGroup,
    VueGoodTable,
    BPagination,
    BCardFooter,
    BFormRadioGroup,
    BCardText,
    BFormSelect,
    BFormTextarea,
    BBadge,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      view: 'grid',
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Visibility',
          field: 'visibility',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      modules: [],
      operations: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      moduletitle: null,
      moduledescription: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
      return newarr[0]
    },
    filteredModules() {
      const searchTerm = this.searchTerm.toLowerCase()
      return this.modules.filter(module => module.title.toLowerCase().includes(searchTerm)
          || module.description.toLowerCase().includes(searchTerm))
    },

  },
  methods: {
    async addModule(bvModalEvent) {
      bvModalEvent.preventDefault()
      const validation = await this.$refs.moduleForm.validate()
      if (!validation) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please fill all required fields',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      this.mutationLoading = true
      const subdomain = this.getIncubatorFromSubdomain() || '.*'
      if (subdomain || this.userOrgs.users_organizationtable.domain === subdomain) {
        await this.$apollo.mutate(
          {
            mutation: gql`mutation MyMutation {
                        ${this.update ? `
                          update_knowledge_resource_module_by_pk(pk_columns: {id: ${this.selectedRow} }, _set: {title: "${this.moduletitle}", description: "${this.moduledescription}", incubator_id: "${this.userOrgs.users_organizationtable.id}", is_private: "${this.is_private}"}) {
                            id
                          }
                        ` : `
                          insert_knowledge_resource_module_one(object: {title: "${this.moduletitle}", description: "${this.moduledescription}", incubator_id: "${this.userOrgs.users_organizationtable.id}", is_private: "${this.is_private}"}) {
                            id
                          }
                        `}
                      }`,
            update: () => {
              this.mutationLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Module ${this.update ? 'updated' : 'added'} successfully`,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('moduleModal')
              this.moduletitle = null
              this.moduledescription = null
              this.$apollo.queries.modules.refetch()
            },
          },
        ).catch(() => {
          this.mutationLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              icon: 'ErrorIcon',
              variant: 'danger',
            },
          })
        })
      }
      return true
    },
    deleteModule(id) {
      // msgOkcofirm
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this module?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                  update_knowledge_resource_module_by_pk(pk_columns: {id: ${id} }, _set: {is_deleted: true}) {
                    id
                  }
                }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.modules.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    modules: {
      query() {
        return gql`
        {
          knowledge_resource_module(where: {_or: [{incubator_id: {_eq: ${this.userOrgs.users_organizationtable.id}}}, {is_default: {_eq: true}}]}) {
            id
            incubator_id
            title
            is_private
            is_default
            description
          }
        }`
      },
      update: data => data.knowledge_resource_module,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
